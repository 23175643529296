<template>
  <div>
    <b-card title="Notifications">
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            aria-controls="notification-table"
            class="float-right"
          ></b-pagination>
          <div class="pb-1">
            <b-table
              id="notification-table"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              striped
              responsive
            >
              <!-- <template #cell(action)="row">
                <b-button variant="primary" size="sm" @click="handleToForm(row.item)">
                  Edit
                </b-button>
              </template> -->
            </b-table>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            aria-controls="notification-table"
            class="float-right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BPagination,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BPagination,
    BFormDatepicker,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "title", sortable: false },
        { key: "station_id", sortable: true },
        { key: "station_name", sortable: true },
        { key: "form_type", sortable: true },
        { key: "tanggal", sortable: true },
        { key: "jam_pengamatan", sortable: true },
        // { key: "action", sortable: false },
      ],
      items: [
        {
          title: "testing00",
          station_id: "testing1",
          station_name: "testing2",
          form_type: "testing3",
          tanggal: "testing4",
          jam_pengamatan: "testing5",
          atribut: "testing7",
          qc_type: "testing6",
          // action: "testing8",
        }
      ],
    };
  },
  computed: {
    rows() {
      console.log('rows', this.items.length)
      return this.items.length;
    }
  },
  mounted() {
    this.getQCNotification();
  },
  methods: {
    getQCNotification() {
      let userLogin = JSON.parse(localStorage.getItem('userLogin'))
		  let userType = userLogin.username
      let ids = ""
      let arrIds = null
      
      if (userLogin.hasOwnProperty('groupIds')) {
        ids = userLogin.groupIds
        arrIds = ids.split(',')
      }

      const config = {
        mode: "cors",
        withCredentials: false,
        params: {
          type_name: "QcNotification",
          qc_flag: 1,
          _metadata: "id,form_type,jam_pengamatan,qc_flag,station_id,station_name,tanggal_pengamatan",
          _size: 10000
        },
        headers: Helper.getHeader(),
      };
      const get_url = Helper.getBaseUrl() + "@aggregation_query";
      axios
        .get(get_url, config)
        .then((response)=>{
          const { data } = response;
          this.items = [];
          data.forEach(row => {
            // const [station_id, form_type, datetime] = row.id.substring("qcnotification_".length).split("_");
            let exist = arrIds ? arrIds.includes(""+row.station_id) : true
            if (exist) {
              this.items.push({
                station_id: row.station_id,
                station_name: row.station_name,
                form_type: row.form_type,
                tanggal: row.tanggal_pengamatan,//.split("T")[0],
                jam_pengamatan: row.jam_pengamatan,
                title: 'Data Suspect',
              });
            }
          });
          // this.items = this.items.sort((a, b) => -(a.tanggal.localeCompare(b.tanggal)) || -(a.jam_pengamatan.localeCompare(b.jam_pengamatan)));
          this.items = this.items.sort((a, b) => new Date(b.tanggal) - new Date(a.tanggal));
        })
        .catch(error=>{
          console.log(error);
        })
    },
  },
};
</script>

<style lang="scss">
</style>
